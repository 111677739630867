<template>
    <div class="cart-item__shipping-info" v-if="discountText">
        <div class="cart-item__shipping-info-text">
            <div class="cart-item__shipping-status" :class="progressBarPercentage == 100 ? 'text-color-default' : ''">
                <span class="cart-item__lock-icon icon fz20" :class="progressBarPercentage == 100 ? 'icon-checkmark' : 'icon-lock'"></span>
                <span :class="progressBarPercentage == 100 ? '' : 'text-decoration-line-through'">{{ discountText }}</span>
            </div>
            <span v-tooltip:top.tooltip="'Gimme more'">
                <button type="button" class="cart-item__info-icon icon icon-info"></button>
            </span>
        </div>
        <div class="cart-item__progress-bar-wrapper">
            <div class="cart-item__progress-bar"></div>
            <div class="cart-item__progress-bar cart-item__progress-bar--filled" :style="{ width : progressBarPercentage + '%' }"></div>
        </div>
    </div>
</template>

<script>
import { number } from "superstruct";
import Tooltip from "./Tooltip.vue";
export default {
    components: {
        Tooltip,
    },
    props: {
        discountText: String,
        progressBarPercentage: Number
    }
}
</script>